module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-prismic-previews@6.0.1_@types+react@18.2.14_gatsby-plugin-image@3.11.0_gatsby-s_fhybs2s6f2lofwmeyvkq6urc2m/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"cloudkitchens-main","accessToken":"MC5aTldRN0JFQUFDSUFyWEJW.KS7vv73vv70NBu-_vU8p77-9eu-_ve-_ve-_ve-_ve-_ve-_vXdJ77-9Bg_vv73vv73vv71T77-9S--_vSXvv70X"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.12.0_babel-plugin-styled-components@2.1.4_gatsby@5.11.0_rea_x4cb2wb2pbl5qfnypwynweia7e/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-transition-link@1.20.5_@babel+core@7.26.10_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Roobert"]}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-mixpanel@4.1.0_gatsby@5.11.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"a6d6d1096ea76d7ae4565134eaea5307","pageViews":"all","trackPageViewsAs":"Page view","mixpanelConfig":{"debug":false,"persistence":"localStorage"}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@5.11.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NWVRV62","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-catch-links@5.11.0_gatsby@5.11.0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-source-wordpress@7.11.0_gatsby-plugin-image@3.11.0_gatsby-plugin-sharp@5.11.0_gatsby-t_j7i6xdh2p7etbqo2odhzvrymje/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://virtualkitchmx.wpenginepowered.com/graphql","debug":{"disableCompatibilityCheck":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"throwRefetchErrors":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"schema":{"timeout":600000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.11.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
